/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class schedule.js
 * This JS file is for showing the list of scheduled meetings with the tutor.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import * as React from "react";
import ScheduleIcon from "../../../assets/images/ScheduleIcon.png";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import parse from "html-react-parser";
import IMG from "../../../views/main/ui_component/img";
import {
  Card,
  Typography,
  Grid,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
  Box,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { fetchScheduleList } from "../../../webservice/students/actions";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import { fetchEnrolledTests } from "../../../webservice/students/actions/MyTestPackageAction";
import moment from "moment-timezone";

const USE_STYLES = makeStyles({
  card: {
    width: "100%",
    height: "383.48px",
    margin: "auto",
    borderRadius: "5px",
    transition: "0.3s",
    // "&:hover": {
    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    // },
  },
  avatar: {
    borderRadius: "5px",
  },
});

const SCHEDULE = () => {
  const classes = USE_STYLES();
  const HISTORY = useHistory();
  const dispatch = useDispatch();
  const { scheduleList } = useSelector((state) => state.dashboardState);
  const [count, setCount] = React.useState(false);
  const { userid } = useSelector((state) => state.authState);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState();
  const [description, setDescription] = React.useState();
  const [tutorDescription, setTutorDescription] = React.useState();
  const [time, setTime] = React.useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (content) => {
    setName(content.name);
    setDescription(content.description);
    setTutorDescription(content.tutordescription);
    setTime(content.timestart);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  useEffect(() => {
    dispatch(fetchScheduleList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (testList?.length > 0) {
      setCount(false);
    } else {
      setCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testList]);

  const generateStatus = (timestamp) => {
    if (timestamp) {
      let eventTimestamp = timestamp * 1000;

      let currDateTime = new Date();
      let currTimestamp = currDateTime.getTime();

      if (eventTimestamp + 1800 > currTimestamp)
        return <span style={{ color: "green" }}>Active</span>;
      else return <span style={{ color: "red" }}>Inactive</span>;
    }
  };
  const melbourneTime = moment.unix(time).tz('Australia/Melbourne');
  const formattedDate = melbourneTime.format('MM/DD/YYYY');
  const formattedTime = melbourneTime.format('h:mm a');
  const timezone = melbourneTime.format('z');

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Typography
              style={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "25.2px",
                paddingLeft: "21px",
                paddingTop: "28px",
              }}
            >
              Schedule
            </Typography>
            <Scrollbars style={{ height: 330 }}>
              {scheduleList && scheduleList.length ? (
                [...scheduleList].reverse()?.map((schedule, index) => (
                  <React.Fragment key={schedule.id}>
                      {index === 0 && ( // Render the button for the first item only
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center" sx={{margin:"10px"}}
                      ><Button
                        variant="contained"
                        onClick={() => HISTORY.push("/schedule")}
                        sx={{
                          backgroundColor: "#634699",
                          textTransform: "none",
                          "&:hover": { backgroundColor: "#634699" },
                        }}
                      >
                          Schedule Now
                        </Button></Grid>
                    )}
                    <List
                      key={schedule.id}
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        textDecoration: "none",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                           sx={{ width: 30, height: 30 }}
                            className={classes.avatar}
                            alt="Task Icon"
                            src={ScheduleIcon}
                          ></Avatar>
                        </ListItemAvatar>
                        <Tooltip title={schedule.name} placement="bottom-end">
                          <Link
                            style={{ textDecoration: "none" }}
                            component="button"
                            variant="body2"
                            onClick={() => {
                              handleClick(schedule);
                              setOpen(!open);
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "19.6px",
                                    textAlign: "left",
                                  }}
                                >
                                  {schedule.name}
                                </Typography>
                              }
                            />
                          </Link>
                        </Tooltip>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        sx={{ backgroundColor: "#634699", color: "#fff" }}
                      >
                        {name}
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2} direction="column" p={2}>
                          <Grid item container xs={12} direction="row">
                            <Grid item xs={1}>
                              <AccessTimeOutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                              <DialogContentText id="alert-dialog-description">
                                {/* {new Date(time * 1000).toLocaleDateString()},{" "}
                                {new Date(time * 1000).toLocaleTimeString()} to{" "}
                                {new Date(
                                  (time + 30 * 60) * 1000
                                ).toLocaleTimeString()} */}
                                {formattedDate},&nbsp;&nbsp;{formattedTime} - &nbsp;({timezone}) 
                              </DialogContentText>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={1}>
                              <DescriptionOutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                              <DialogContentText id="alert-dialog-description">
                                {description
                                  ? description.replace(/<[^>]+>/g, "")
                                  : null}
                              </DialogContentText>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={1}>
                              <StickyNote2OutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                            <DialogContentText id="alert-dialog-description">
          {/* Display the tutor description */}
          {tutorDescription ? tutorDescription.replace(/<[^>]+>/g, "") : "No Tutor Description"}
        </DialogContentText>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={1} display="flex">
                              <EventOutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                              <DialogContentText id="alert-dialog-description">
                                {generateStatus(time)}
                              </DialogContentText>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </Dialog>
                  </React.Fragment>
                ))
              ) : (
                <Box sx={{ lineHeight: "19.6px", textAlign: "center" }}>
                  <IMG width="170px" />
                  <Typography
                    sx={{
                      color: "#718096",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      textAlign: "center",
                    }}
                  >
                    No Schedule
                  </Typography>
                  <br />
                  <Button
                    disabled={count}
                    onClick={() => HISTORY.push("/schedule")}
                    variant="contained"
                    sx={{
                      backgroundColor: "#634699",
                      textTransform: "none",
                      "&:hover": { backgroundColor: "#634699" },
                    }}
                  >
                    Schedule Now
                  </Button>
                </Box>
              )}
            </Scrollbars>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default SCHEDULE;
